//For a new demo, need to update values here, and also the primary and accent palettes in tailwind.config.js
export const demo = {
  partnerName: 'NAB',
  assetsLogoPath: 'assets/images/logos/',
  partnerFaviconAlt: 'NAB Logo',
  partnerUrlReplacement: 'nab.tilled.com',
  partnerFaviconLogo: 'favicon.png', //browser tab and loading logo (transparent bg)
  partnerLightLogo: 'NAB-logo-color.png', //light mode logo (dark text, transparent bg)
  partnerDarkLogo: 'NAB-logo-white.png', //dark mode logo (light text, transparent bg)
};

document.title = demo.partnerName;
document.getElementById('logo').style.display = '';
document.getElementById('logoImg').setAttribute('alt', demo.partnerFaviconAlt);
document.getElementById('logoImg').setAttribute('src', demo.assetsLogoPath + demo.partnerDarkLogo); //based on color this may need to be partnerListLogo
